import ajax from "./ajax.js";
import Qs from "qs";

let base = process.env.VUE_APP_BASE_URL;

const addTicket = (params) => {
  return ajax.post(`${base}/api/itedu/v1/ticket`, Qs.stringify(params));
};

const listTicket = (courseId, pageNo) => {
  return ajax.get(
    `${base}/api/itedu/v1/ticket/query?courseId=${courseId}&pageNo=${pageNo}`
  );
};

const queryUsedTicket = (courseId, pageNo) => {
  return ajax.get(
    `${base}/api/itedu/v1/ticket/used/by-course?courseId=${courseId}&pageNo=${pageNo}`
  );
};

const queryUsedTicketByPhone = (phone) => {
  return ajax.get(`${base}/api/itedu/v1/ticket/used/by-phone?phone=${phone}`);
};

export const ticketApi = {
  addTicket: addTicket,
  listTicket: listTicket,
  queryUsedTicket: queryUsedTicket,
  queryUsedTicketByPhone: queryUsedTicketByPhone,
};
