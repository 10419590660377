<template>
  <div>
    <el-form
      :label-position="'left'"
      label-width="80px"
      @submit.prevent="onSubmit"
      style="margin: 20px; width: 60%; min-width: 600px"
    >
      <el-form-item label="课程id">
        <el-input v-model="requestContent.courseId"></el-input>
      </el-form-item>
      <el-form-item label="数量">
        <el-input placeholder="100" v-model="requestContent.number"></el-input>
      </el-form-item>
    </el-form>
    <el-form
      :label-position="'left'"
      label-width="80px"
      @submit.prevent="onSubmit"
      style="margin: 20px; width: 60%; min-width: 600px"
    >
      <el-form-item class="bottomBtn">
        <el-button @click="onAddTicket">创建</el-button>
        <el-button>取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { alertMessage } from "@/util/alertMessageUtil";
import { ticketApi } from "@/api/ticketApi";
export default {
  data() {
    return {
      requestContent: {
        courseId: 0,
        number: 0,
      },
    };
  },
  methods: {
    onAddTicket() {
      if (this.requestContent.courseId > 0 && this.requestContent.number > 0) {
        let param = {
          courseId: this.requestContent.courseId,
          number: this.requestContent.number,
        };
        ticketApi.addTicket(param).then((ret) => {
          if (ret.code === 0) {
            alertMessage("发布成功", "success");
          } else {
            alertMessage(ret.msg, "warning");
          }
        });
      } else {
        alertMessage("数量不能为0", "warning");
      }
    },
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.switch-item {
  margin: 0 10px;
}
</style>
